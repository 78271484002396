import React from "react";
import ContenedorPiedra from "../../components/Piedras";

function Piedras() {
  return (
    <>
      <ContenedorPiedra />
    </>
  );
}

export default Piedras;
